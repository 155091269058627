import { useStyletron } from 'baseui'
import { ProgressSteps, Step } from 'baseui/progress-steps'
import { Button, KIND, SIZE } from 'baseui/button'
import { Select, SIZE as SelectSize } from 'baseui/select'
import React from 'react'
import { statusToStep } from '../../core/utils'
import { useMutation, useQueryClient } from 'react-query'
import { updatePaperStatus } from '../../core/apis'
import { ButtonGroup } from 'baseui/button-group'
import Confirm from '../Confirm'
import EmailTemplate from './EmailTemplate'
import { StateChoices, StatusEnum } from '../../core/options';
import FlexBlock from '../FlexBlock'
import { init } from '@milkdown/core'
import StatusTag from "../StatusTag";

export default function Timeline({ id, initial, status }) {
	id = parseInt(id)
	const [step, setStep] = React.useState(initial || 0)
	const [selectValue, setSelectValue] = React.useState()
	const [selectError, setSelectError] = React.useState(false)
	const [modalOpen, setModalOpen] = React.useState([false, false])
	const [, theme] = useStyletron()

	const [overrides, setOverrides] = React.useState({
		Root: {
			style: {
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				width: '100%',
				minWidth: '100%',
				margin: '2rem 0',
				padding: 0,
				textAlign: 'center',
				wordBreak: 'keep-all',
			},
		},
		StepRoot: {
			style: {
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				marginBottom: 'auto',
				maxWidth: '25%',
				minWidth: '25%',
				zIndex: 0,
			},
		},
		Content: {
			style: {
				display: 'flex',
				flexDirection: 'column',
				marginLeft: 0,
				flexWrap: 'wrap',
			},
		},
		Icon: {
			style: {
				transform: 'translateY(2px)',
			},
		},
		IconContainer: {
			style: {
				width: '16px',
				height: '16px',
				boxSizing: 'border-box',
				marginTop: '24px',
				zIndex: 1,
			},
		},
		Title: {
			style: {
				height: '30px',
				letterSpacing: '-0.02em',
				fontSize: '14px',
				'@media screen and (max-width: 768px)': {
					fontSize: '14px',
				},
			},
		},
		Tail: {
			style: {
				left: '0',
				right: '0',
				width: '100%',
				height: '2px',
				marginTop: '33px',
				marginLeft: 'auto',
				marginRight: 'auto',
				transform: 'translateX(50%)',
			},
		},
	})

	const queryClient = useQueryClient()
	const updateStatusMutation = useMutation(updatePaperStatus)

	function _handleUpdateStatus(state, templateLabel = null) {
		const payload = { state, id };
	
		if (templateLabel) {
			payload.template = templateLabel;
		}
	
		updateStatusMutation.mutate(
			payload,
			{
				onSuccess: () => {
					setStep(statusToStep(state));
					
					// 동시에 여러 쿼리를 무효화
					Promise.all([
						queryClient.invalidateQueries(['paperDetail', { id }], { exact: true }),
						queryClient.invalidateQueries('papers')
					])
					.then(() => {
						console.log('All queries invalidated');
					})
					.catch((error) => {
						console.error('Error invalidating queries:', error);
					});
				},
				onError: (error) => {
					console.error('Failed to update status:', error);
				}
			}
		);
	}

	function handleNew() {
		_handleUpdateStatus(StateChoices.NEW)
	}

	function handleProceed() {
		_handleUpdateStatus(StateChoices.ALIGNING)
	}

	function handleDecline(templateLabel) {
		_handleUpdateStatus(StateChoices.REJECTED, templateLabel)
	}

	function handleStartProject() {
		_handleUpdateStatus(StateChoices.IN_PROGRESS)
	}

	function handleComplete() {
		_handleUpdateStatus(StateChoices.IN_PROGRESS)
	}

	function handleCancelled() {
		_handleUpdateStatus(StateChoices.CANCELLED)
	}

	function handleUpdateStatus() {
		if (selectValue) {
			_handleUpdateStatus(selectValue[0].id)
		}
		setModalOpen([false, false])
		setSelectValue(null)
	}

	function handleBack(status) {
		console.log({status})

		if (status === 'new') return;

		if (status === 'rejected' || status === 'aligning') {
			handleNew()
		}

		if (status === 'cancelled' || status === 'in_progress') {
			handleProceed()
		}
	}

	function closeModals() {
		setModalOpen([false, false, false])
	}

	return (
		<>

		{/* <StatusTag id="detail-tag" status={status} size={SIZE.medium} style={{ maxHeight: '24px !important', padding: '2px 8px !important', width: '45px' }} /> */}
		{/* img */}
		{/* <div className={`badge-status --${status}`} /> */}
		{/* 대기 */}
		{status === 'new' && 
			<FlexBlock style={{gap: '10px', marginBottom: '0' }} id="button-blocks">
				<Button id="button-accept" 
					className={`check-button ${status == 'aligning' ? 'selected': '' }`} 
					size="compact" 
					onClick={handleProceed} 
					children="접수" 
					disabled={status == 'aligning'} 
				/>
				<Button id="button-reject" 
					className={`check-button ${status == 'rejected' ? 'selected': '' }`} 
					size="compact" 
					kind={KIND.secondary} 
					onClick={() => setModalOpen([false, false, true])} 
					children="거절" 
					disabled={status == 'rejected'}
				/>
			</FlexBlock>
		}

		{status === 'aligning' && 
			<>
			<FlexBlock style={{gap: '10px', marginBottom: '0' }} id="button-blocks">
				<Button id="button-close" 
					className={`check-button ${status == 'completed' ? 'selected': '' }`} 
					size="compact" 
					onClick={handleComplete} 
					children="계약" 
					disabled={status == 'completed'} 
				/>
				<Button id="button-cancelled" 
					className={`check-button ${status == 'cancelled' ? 'selected': '' }`} 
					size="compact" 
					kind={KIND.secondary} 
					onClick={handleCancelled} 
					children="종료" 
					disabled={status == 'cancelled'} 
				/>
			</FlexBlock>
			<FlexBlock style={{gap: '10px', marginTop: '-30px', marginBottom: '0' }} id="button-blocks">
				<Button id="button-back" 
					className={`check-button ${status == 'new' ? 'selected': '' }`} 
					size="compact" onClick={() => handleBack(status)} children="← 돌아가기" disabled={status == 'new'} />
			</FlexBlock>
			</>
		}

		{status === 'rejected' && 
			<FlexBlock style={{gap: '10px', marginBottom: '0' }} id="button-blocks">
				<Button id="button-back" 
					className={`check-button ${status == 'new' ? 'selected': '' }`} 
					size="compact" onClick={() => handleBack(status)} children="← 돌아가기" disabled={status == 'new'} />
			</FlexBlock>
		}

		{status === 'in_progress' && 
			<FlexBlock style={{gap: '10px', marginBottom: '0' }} id="button-blocks">
				<Button id="button-back" 
					className={`check-button ${status == 'new' ? 'selected': '' }`} 
					size="compact" onClick={() => handleBack(status)} children="← 돌아가기" disabled={status == 'new'} />
			</FlexBlock>
		}

		{status === 'cancelled' && 
			<FlexBlock style={{gap: '10px', marginBottom: '0' }} id="button-blocks">
				<Button id="button-back" 
					className={`check-button ${status == 'new' ? 'selected': '' }`} 
					size="compact" onClick={() => handleBack(status)} children="← 돌아가기" disabled={status == 'new'} />
			</FlexBlock>
		}
		{/* <div className='message'>* 변경을 원하는 프로젝트의 상태를 선택해 주세요.</div> */}

					{/* <ButtonGroup size={SIZE.compact} selected={1}>
						<Select
							error={selectError}
							clearable={false}
							deleteRemoves={false}
							size={SelectSize.compact}
							options={[
								{ label: '접수대기', id: 'new' },
								{ label: '협의진행', id: 'aligning' },
								{ label: '프로젝트 진행', id: 'in_progress' },
							]}
							overrides={{ Root: { style: { display: 'inline-flex', width: '128px' } } }}
							value={selectValue}
							placeholder="상태 선택"
							onChange={(params) => {
								setSelectValue(params.value)
								setSelectError(false)
							}}
						/>
						<Button kind={KIND.primary} size={SIZE.compact} onClick={() => setModalOpen([true, false, false])} children="변경" />
					</ButtonGroup> */}
			<Confirm
				isOpen={modalOpen[0]}
				onClose={closeModals}
				onConfirm={() => {
					if (!selectValue) {
						setSelectError(true)
					} else {
						handleUpdateStatus()
						closeModals()
					}
				}}
				body="프로젝트의 상태 변경을 계속 진행할까요?"
			/>
			<Confirm
				isOpen={modalOpen[1]}
				onClose={closeModals}
				onConfirm={() => {
					handleComplete()
					closeModals()
				}}
				body="프로젝트를 종료할까요?"
			/>
			<EmailTemplate
				onClose={closeModals}
				onSubmit={(templateLabel) => {
					handleDecline(templateLabel)
					closeModals()
				}}
				isOpen={modalOpen[2]}
				paper={parseInt(id)}
			/>
		</>
	)
}
