import { styled } from 'baseui'
import { Chart } from 'chart.js'
import { useEffect, useRef, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import gsap from 'gsap'

const Container = styled('div', ({ $theme }) => ({
    display:'grid',
    gridTemplateColumns:'repeat(auto-fill, minmax(375px, auto))',
	alignItems:'center',
	rowGap:'3rem',
	columnGap:'25px',
	justifyItems:'center',
	'@media screen and (max-width:768px)':{
		gridTemplateColumns:'repeat(auto-fill, minmax(100%, auto))',
	}
}))

const Wrapper = styled('div', ({ $theme }) => ({
	position: 'relative',
	width: '50%',
	minWidth:'375px',
	'@media screen and (max-width:768px)':{
		minWidth:'unset',
		maxWidth:'375px',
		width:'100%'
	}
}))

const CustomLegend = styled('div', ({ $theme }) => ({
	position: 'relative',
	maxWidth:'335px',
	margin:'0 auto',
	display:'grid',
	rowGap:'14px',
	color:'#666666'
}))

const LegendItem = styled('div', ({ $theme }) => ({
	fontSize:'12px',
	display:'grid',
	gridTemplateColumns:'1fr 50px',
	columnGap:'10px',
	opacity:0,
	visibility:'hidden',
	transform:'translate(0, 8px)'
}))

const LegendLabel = styled('div', ({ $theme }) => ({
	display:'-webkit-box',
	WebkitLineClamp:'1',
	textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
	lineHeight:'1.2'
}))

const LegendValue = styled('div', ({ $theme }) => ({
	justifySelf:'flex-end',
	fontWeight:700
}))

const ColorBox = styled('span', ({ $theme }) => ({
	width:'10px',
	height:'10px',
	display:'inline-block',
	marginRight:'10px'
}))

const getOrCreateTooltip = (chart) => {
	let tooltipEl = chart?.canvas?.parentNode?.querySelector('.custom-tooltip')

	if (!tooltipEl) {
		tooltipEl = document.createElement('div')
        tooltipEl.classList.add('custom-tooltip')
		const textEl = document.createElement('div')

		tooltipEl.style.width = '100%'
		tooltipEl.style.height = '100%'

		textEl.classList.add('tooltip-text')
		tooltipEl.style.position = 'absolute'
		tooltipEl.style.top = '50%'
		tooltipEl.style.zIndex = '1'
		tooltipEl.style.transform = 'translate(0, -50%)'

		tooltipEl.style.display = 'flex'
		tooltipEl.style.justifyContent = 'center'
		tooltipEl.style.alignItems = 'center'
		tooltipEl.style.opacity = '0'

		tooltipEl.appendChild(textEl)

		chart.canvas.style.position = 'relative'
		chart.canvas.style.zIndex = '2'
		chart.canvas.parentNode?.appendChild(tooltipEl)
	}

	return tooltipEl
}

const doughnutTooltipHandler = (ctx) => {
	if('ontouchstart' in document.documentElement) return;
	const { chart, tooltip } = ctx

	const tooltipEl = getOrCreateTooltip(chart)
	const textEl = tooltipEl.querySelector('.tooltip-text')

	let text = tooltip.body[0].lines[0]
	text = text.split(':')[0]
	textEl.innerHTML = `전체<span>100%</span>`

	if (tooltip.opacity === 1) {
		textEl.innerHTML = `${text}`
		return
	} 
}

const getBg = (op) => {
	return `rgba(75, 202, 129, ${op})`
}

const getHoverBg = (op) => {
	return `rgba(75, 202, 129, ${op})`
}
export default function TaskRatioChart(props) {
	const { taskRatio } = props
	const [isLoading, setIsLoading] = useState(true)
	const legendWrapRef = useRef(null)
	const chartWrapRef = useRef(null)
	const [doughnutData, setDoughnutData] = useState({
		labels: [],
		datasets: [],
	})

	const doughnutOptions = {
		borderWidth: 0.3,
		borderColor:'#fff',
		plugins: {
			tooltip: {
				enabled: false,
				position: 'nearest',
				external: doughnutTooltipHandler,
			},
			legend: {
				display: false,
			},
		},
		animation:{
			onStart: function(ctx){
				const tooltipEl = getOrCreateTooltip(ctx.chart)
				const textEl = tooltipEl.querySelector('.tooltip-text')

				let text = `전체<span>100%</span>`
				textEl.innerHTML = `${text}`	
			},
			onComplete: function(ctx){
				const chart = ctx.chart;
				chart.options.animation.duration = 0;
			}
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			const temp = { 
				labels: [],
				datasets: [],
			};
			const data = [];
			const background = [];
            const hoverBackground = [];

			taskRatio.sort((a, b) => {
				if (a.ratio < b.ratio) {
					return 1
				} else {
					return -1
				}
			})

			const taskLength = taskRatio.length

			for (let i = 0; i < taskLength; i++) {
				const ratio = taskRatio[i]
				// Legend 우측 label text 설정
				temp.labels.push(`${ratio.name}<span>${ratio.ratio}%<span>`);
				data.push(ratio.ratio);
                const op = (taskLength - i) / taskLength
				background.push(getBg(op));
				hoverBackground.push(getHoverBg(op));
			}

			temp.datasets.push({
				data,
				backgroundColor: background,
                hoverBackgroundColor: hoverBackground
			})

			return temp
		}

		fetchData().then((result) => {
			setDoughnutData(result)
			setIsLoading(false)
		})
	}, [taskRatio])

	useEffect(() => {
		if(legendWrapRef.current && chartWrapRef.current){
			const legendItems = legendWrapRef.current.querySelectorAll('.legend-item');
			const tooltipEl = chartWrapRef.current.querySelectorAll('.custom-tooltip');

			
			const tl = gsap.timeline().addLabel('start');

			tl.to(tooltipEl, {
				opacity:1,
				duration:0.5,
				ease:'power1.inOut'
			}, 'start+=0.4')

			tl.to(legendItems,{
				autoAlpha:1,
				duration:0.4,
				stagger:0.04,
				ease:'power1.inOut'
			}, 'start+=0.56')

			tl.to(legendItems, {
				y:0,
				duration:0.4,
				stagger:0.04,
				ease:'power2.out'
			}, 'start+=0.56')
		}
	}, [legendWrapRef, isLoading, chartWrapRef])

	return <Container>
        {!isLoading && <>
            <Wrapper ref={chartWrapRef}>
                <Doughnut data={doughnutData} options={doughnutOptions} />
            </Wrapper>
            <Wrapper ref={legendWrapRef}>
                <CustomLegend>
                    {taskRatio.map((ratio, i) => (
                        <LegendItem className='legend-item' key={i}>
                            <LegendLabel>
								<ColorBox style={{backgroundColor:getBg((taskRatio.length - i) / taskRatio.length)}} />
								{ratio.name}
							</LegendLabel>
                            <LegendValue>{ratio.ratio}%</LegendValue>
                        </LegendItem>
                    ))}
                </CustomLegend>
            </Wrapper>
        </>}
    </Container>
}